<template>
    <psi-dialog
        v-if="$_.has(currentApplication, 'applicants')"
        icon="mdi-clipboard-text-multiple"
        :indicator="currentApplication.applicants.length"
        title="Application"
        hide-actions
        :hide-title="print"
        @close="$emit('close')"
    >
        <div class="page">
            <div class="d-flex flex-row">
                <div class="d-flex flex-column mb-4" style="width: 100%">
                    <h3 class="mb-2">
                        Application Progress {{ currentApplication.progress }}%
                    </h3>
                    <v-progress-linear
                        :value="currentApplication.progress"
                        height="10"
                        rounded
                        color="accent"
                    ></v-progress-linear>
                </div>
            </div>
            <h3 class="mb-3">Details</h3>
            <v-row>
                <v-col :cols="print ? 4 : 2">
                    <v-card class="pa-6" color="accent darken-1">
                        <div class="d-flex flex-column">
                            <v-icon size="100" color="accent lighten-1"
                                >mdi-home-city</v-icon
                            >
                        </div>
                    </v-card>
                </v-col>
                <v-col :cols="print ? 8 : 3">
                    <v-list
                        class="mt-n3"
                        :width="`${screen ? 300 : 450}`"
                        dense
                    >
                        <template v-for="(item, index) in items">
                            <v-list-item :key="index">
                                <v-list-item-content>
                                    <div class="d-flex align-center">
                                        <v-icon class="mr-4" color="accent">{{
                                            item.icon
                                        }}</v-icon>
                                        <span
                                            class="d-block mr-2 accent--text"
                                            >{{ item.label }}</span
                                        >
                                        <v-spacer></v-spacer>
                                        <span class="d-block font-weight-bold">
                                            {{
                                                $_.get(
                                                    currentApplication,
                                                    item.key
                                                )
                                            }}
                                        </span>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                                :key="`divider-${index}`"
                                v-if="index != items.length - 1"
                            ></v-divider>
                        </template>
                    </v-list>
                </v-col>
                <v-col :cols="print ? 12 : 7" class="d-flex">
                    <household-monthly-income
                        :summary="
                            $_.get(
                                currentApplication.applicants[0],
                                'order.household_summary'
                            )
                        "
                        hide-actions
                    ></household-monthly-income>
                    <psi-stats-card
                        class="ml-2"
                        :colorOption="3"
                        icon="mdi-aspect-ratio"
                        title="Income to Rent Ratio"
                        :stat="ratio"
                        :action="false"
                        hide-actions
                    >
                    </psi-stats-card>
                </v-col>
            </v-row>
            <!-- <div
            class="d-flex align-start fill-height justify-space-between align-items-center"
        >
         
        
          
           
        </div>
        -->
            <h3 class="mb-3">Applicants</h3>

            <v-list elevation="2" dense>
                <template
                    v-for="(applicant, index) in currentApplication.applicants"
                >
                    <v-list-item :key="index">
                        <v-list-item-icon>
                            <v-icon color="accent" class="mt-2"
                                >mdi-clipboard-account</v-icon
                            >
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                applicant.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                                applicant.type
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <div class="d-flex flex-column" style="width: 40%">
                            <h5
                                class="font-weight-regular text-caption accent-text"
                            >
                                Progress: {{ applicant.screen.progress }}%
                            </h5>
                            <v-progress-linear
                                color="accent"
                                :value="applicant.screen.progress"
                            ></v-progress-linear>
                        </div>
                        <v-spacer></v-spacer>
                        <v-list-item-action v-if="screen">
                            <psi-action-drop-down
                                @action="
                                    handleAction(
                                        applicant.id,
                                        applicant.screen.order_id,
                                        $event
                                    )
                                "
                                :heading="`${applicant.name} Actions`"
                                headingColor="accent"
                                :items="actionMenuItems"
                                btnClass="accent"
                            >
                            </psi-action-drop-down>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider
                        :key="`divider-${index}`"
                        v-if="index != currentApplication.applicants.length - 1"
                    ></v-divider>
                </template>
            </v-list>
            <div class="d-flex align-center my-3" v-if="screen">
                <v-btn class="accent mr-4" @click.stop="showSearch = true">
                    <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
                    Add Applicant
                </v-btn>

                <v-text-field
                    v-show="showSearch"
                    prepend-icon="mdi-account-search"
                    append-icon="mdi-magnify-close"
                    placeholder="Search by name or email"
                    @click:append="showSearch = false"
                >
                </v-text-field>
            </div>
        </div>
        <div class="page" :style="{ marginTop: print ? '80px' : '0px' }">
            <h3 class="mb-3">Rental Payment History</h3>
            <household-summary-payments
                hide-title
                :items="
                    $_.get(
                        currentApplication.applicants[0],
                        'order.household_summary.rentals'
                    )
                "
            ></household-summary-payments>
        </div>
        <div class="page" :style="{ marginTop: print ? '80px' : '0px' }">
            <h3 class="my-3">Household Incomes</h3>
            <household-summary-incomes
                hide-title
                :items="
                    $_.get(
                        currentApplication.applicants[0],
                        'order.household_summary.incomes'
                    )
                "
            ></household-summary-incomes>
        </div>
    </psi-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "screening-process-application",
    components: {
        HouseholdSummaryPayments: () =>
            import("./ScreeningHouseholdSummaryPayments"),
        HouseholdSummaryIncomes: () =>
            import("./ScreeningHouseholdSummaryIncome"),
        HouseholdMonthlyIncome: () =>
            import("@components/screen/components/HouseholdMonthlyIncome"),
    },
    props: {
        application_id: {
            type: [String, Number],
            required: true,
        },
        media: {
            type: String,
            required: false,
            default: "screen",
        },
    },
    data() {
        return {
            showSearch: false,
            actionMenuItems: [
                {
                    icon: "mdi-clipboard-account",
                    title: "View Order",
                    class: "accent--text text--darken-1",
                },
                {
                    divider: true,
                },
                {
                    icon: "mdi-email",
                    title: "Send Email",
                    class: "alternate2--text text--darken-1",
                },
                {
                    icon: "mdi-message",
                    title: "Send Text",
                    class: "alternate2--text text--darken-1",
                },
                {
                    icon: "mdi-phone",
                    title: "Call",
                    class: "alternate2--text text--darken-1",
                },
                {
                    divider: true,
                },
                {
                    icon: "mdi-account-multiple-remove",
                    title: "Remove from Application",
                    class: "alternate1--text text--darken-3",
                },
            ],
            items: [
                {
                    key: "property.name",
                    label: "Property",
                    icon: "mdi-home-city",
                },
                {
                    key: "unit",
                    label: "Unit",
                    icon: "mdi-door-open",
                },
                {
                    key: "rent",
                    label: "Rent",
                    icon: "mdi-cash-usd",
                },
                {
                    key: "source",
                    label: "Source",
                    icon: "mdi-clipboard-arrow-right",
                },

                // {
                //     key: "created_at",
                //     label: "Created",
                //     icon: "mdi-calendar",
                // },
            ],
        };
    },
    computed: {
        ...mapGetters("Screens", ["currentApplication", "loading"]),
        print() {
            return this.media === "print";
        },
        screen() {
            return this.media === "screen";
        },
        ratio: {
            immediate: true,
            deep: true,
            get() {
                return (
                    this.currentApplication.applicants[0].order
                        .household_summary.income_total /
                    parseFloat(
                        this.currentApplication.rent
                            .replace("$", "")
                            .replace(",", "")
                    )
                ).toLocaleString("en-US", { maximumFractionDigits: 1 });
            },
        },
    },
    created() {},
    methods: {
        ...mapActions("Screens", ["getCurrentApplication"]),
        handleAction(id, order_id, action) {
            if (action.title === "View Order") {
                this.$router.push({
                    name: "Application Process",
                    params: { id: order_id },
                });
                this.$emit("close");
            }
        },
    },
};
</script>

<style>
.trix-button--icon-attach {
    display: none !important;
}
.trix-button-group--file-tools {
    display: none !important;
}
.trix-content {
    min-height: 12em !important;
}
.ps {
    height: 240px;
}
</style>
