var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$_.has(_vm.currentApplication, "applicants")
    ? _c(
        "psi-dialog",
        {
          attrs: {
            icon: "mdi-clipboard-text-multiple",
            indicator: _vm.currentApplication.applicants.length,
            title: "Application",
            "hide-actions": "",
            "hide-title": _vm.print
          },
          on: {
            close: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "page" },
            [
              _c("div", { staticClass: "d-flex flex-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column mb-4",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("h3", { staticClass: "mb-2" }, [
                      _vm._v(
                        " Application Progress " +
                          _vm._s(_vm.currentApplication.progress) +
                          "% "
                      )
                    ]),
                    _c("v-progress-linear", {
                      attrs: {
                        value: _vm.currentApplication.progress,
                        height: "10",
                        rounded: "",
                        color: "accent"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("h3", { staticClass: "mb-3" }, [_vm._v("Details")]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.print ? 4 : 2 } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-6",
                          attrs: { color: "accent darken-1" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    size: "100",
                                    color: "accent lighten-1"
                                  }
                                },
                                [_vm._v("mdi-home-city")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.print ? 8 : 3 } },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "mt-n3",
                          attrs: {
                            width: "" + (_vm.screen ? 300 : 450),
                            dense: ""
                          }
                        },
                        [
                          _vm._l(_vm.items, function(item, index) {
                            return [
                              _c(
                                "v-list-item",
                                { key: index },
                                [
                                  _c("v-list-item-content", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-4",
                                            attrs: { color: "accent" }
                                          },
                                          [_vm._v(_vm._s(item.icon))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block mr-2 accent--text"
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-block font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$_.get(
                                                    _vm.currentApplication,
                                                    item.key
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              ),
                              index != _vm.items.length - 1
                                ? _c("v-divider", { key: "divider-" + index })
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex",
                      attrs: { cols: _vm.print ? 12 : 7 }
                    },
                    [
                      _c("household-monthly-income", {
                        attrs: {
                          summary: _vm.$_.get(
                            _vm.currentApplication.applicants[0],
                            "order.household_summary"
                          ),
                          "hide-actions": ""
                        }
                      }),
                      _c("psi-stats-card", {
                        staticClass: "ml-2",
                        attrs: {
                          colorOption: 3,
                          icon: "mdi-aspect-ratio",
                          title: "Income to Rent Ratio",
                          stat: _vm.ratio,
                          action: false,
                          "hide-actions": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h3", { staticClass: "mb-3" }, [_vm._v("Applicants")]),
              _c(
                "v-list",
                { attrs: { elevation: "2", dense: "" } },
                [
                  _vm._l(_vm.currentApplication.applicants, function(
                    applicant,
                    index
                  ) {
                    return [
                      _c(
                        "v-list-item",
                        { key: index },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-2",
                                  attrs: { color: "accent" }
                                },
                                [_vm._v("mdi-clipboard-account")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(applicant.name))
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(_vm._s(applicant.type))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-column",
                              staticStyle: { width: "40%" }
                            },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "font-weight-regular text-caption accent-text"
                                },
                                [
                                  _vm._v(
                                    " Progress: " +
                                      _vm._s(applicant.screen.progress) +
                                      "% "
                                  )
                                ]
                              ),
                              _c("v-progress-linear", {
                                attrs: {
                                  color: "accent",
                                  value: applicant.screen.progress
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _vm.screen
                            ? _c(
                                "v-list-item-action",
                                [
                                  _c("psi-action-drop-down", {
                                    attrs: {
                                      heading: applicant.name + " Actions",
                                      headingColor: "accent",
                                      items: _vm.actionMenuItems,
                                      btnClass: "accent"
                                    },
                                    on: {
                                      action: function($event) {
                                        return _vm.handleAction(
                                          applicant.id,
                                          applicant.screen.order_id,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      index != _vm.currentApplication.applicants.length - 1
                        ? _c("v-divider", { key: "divider-" + index })
                        : _vm._e()
                    ]
                  })
                ],
                2
              ),
              _vm.screen
                ? _c(
                    "div",
                    { staticClass: "d-flex align-center my-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "accent mr-4",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.showSearch = true
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mdi-account-multiple-plus")
                          ]),
                          _vm._v(" Add Applicant ")
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showSearch,
                            expression: "showSearch"
                          }
                        ],
                        attrs: {
                          "prepend-icon": "mdi-account-search",
                          "append-icon": "mdi-magnify-close",
                          placeholder: "Search by name or email"
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.showSearch = false
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "page",
              style: { marginTop: _vm.print ? "80px" : "0px" }
            },
            [
              _c("h3", { staticClass: "mb-3" }, [
                _vm._v("Rental Payment History")
              ]),
              _c("household-summary-payments", {
                attrs: {
                  "hide-title": "",
                  items: _vm.$_.get(
                    _vm.currentApplication.applicants[0],
                    "order.household_summary.rentals"
                  )
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "page",
              style: { marginTop: _vm.print ? "80px" : "0px" }
            },
            [
              _c("h3", { staticClass: "my-3" }, [_vm._v("Household Incomes")]),
              _c("household-summary-incomes", {
                attrs: {
                  "hide-title": "",
                  items: _vm.$_.get(
                    _vm.currentApplication.applicants[0],
                    "order.household_summary.incomes"
                  )
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }